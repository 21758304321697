<template>
  <div class="container-fluid unauhtorized text-center">
    <div class="row">
      <div class="col">
        <img class="img-fluid pj"
             src="../assets/images/pj_panda_fist_bump.png"
             alt="pj-panda" />
        <h1 class="heading">OOPS!</h1>
        <p class="small mb-0">You're not allowed to view this page</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name : 'Unauthorized',
  }
</script>

<style lang="scss" scoped>
  @import '../assets/scss/components/unauthorized';
</style>
